import React, { useEffect, useState } from 'react'
import { useUser } from '../auth/UserContext'
import { useNavigate } from 'react-router-dom'

const tabs = [
  { name: 'All', href: '#', current: false },
  { name: 'Accepted', href: '#', current: false },
  { name: 'Applied', href: '#', current: false },
  { name: 'Rejected', href: '#', current: true }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const SavedPrograms = () => {
  const [savedPrograms, setSavedPrograms] = useState([])
  const { user } = useUser() // Access the user context
  const navigate = useNavigate()

  useEffect(() => {
    if (user && user.uid) {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
      const fetchSavedPrograms = async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/get_favorites/${user.uid}`
          )
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          const data = await response.json()
          setSavedPrograms(data)
        } catch (error) {
          console.error(
            'There was a problem fetching the saved programs:',
            error
          )
        }
      }

      fetchSavedPrograms()
    }
  }, [user])

  // Conditional rendering based on savedPrograms being an array
  if (savedPrograms.length === 0) {
    return (
      <div className='px-4 sm:px-6 lg:px-8'>
        <h1 className='text-lg font-semibold leading-6 text-gray-800'>
          Saved Programs
        </h1>
        <p className='mt-2 text-sm text-gray-600'>No saved programs found.</p>
      </div>
    )
  }

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-600'>
            Saved programs
          </h1>
          <p className='mt-2 text-sm text-gray-400'>
            Review your saved affiliate programs
          </p>
        </div>
      </div>
      {/* <div className="border-b border-gray-200 pb-5 sm:pb-0">
      <h3 className="text-base font-semibold leading-6 text-gray-900">Candidates</h3>
      <div className="mt-3 sm:mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div> */}
      {/* <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Program name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Offer
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date applied
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Commission type
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-right">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                {savedPrograms.map((program) => (
                  <tr key={program.product_id}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="h-9 w-9 flex-shrink-0">
                          <img className="h-9 w-9 rounded-full" src={program.logo} alt="" />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-600">{program.name}</div>
                          <div className="mt-1 text-gray-400">{program.offer}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">{person.title}</div>
                      <div className="mt-1 text-gray-500">{person.department}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Active
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{person.role}</td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a href="#" className="text-indigo-600 hover:text-indigo-900">
                        Edit<span className="sr-only">, {person.name}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      <div className='mt-8 flow-root'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                  >
                    Name
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Title
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Status
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Date applied
                  </th>
                  <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-0'>
                    <span className='sr-only'>Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {savedPrograms.map(program => (
                  <tr key={program.product_id}>
                    <td className='whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0'>
                      <div className='flex items-center'>
                        <div className='h-09 w-09 flex-shrink-0'>
                          <img
                            className='h-8 w-8 rounded-full'
                            src={program.logo}
                            alt=''
                          />
                        </div>
                        <div className='ml-4'>
                          <div
                            className='font-semibold text-base text-gray-600'
                            title={program.name}
                          >
                            {program.name.length > 50
                              ? `${program.name.substring(0, 50)}...`
                              : program.name}
                          </div>
                          {/* Safely render offer or a fallback message */}
                          <div
                            className='mt-1 font-normal text-gray-400 text-sm'
                            title={program.offer}
                          >
                            {program.offer
                              ? program.offer.length > 50
                                ? `${program.offer.substring(0, 50)}...`
                                : program.offer
                              : 'No offer available'}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-5 text-sm text-gray-500'>
                      <div className='mt-1 text-gray-500'>{program.name}</div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-5 text-sm text-gray-500'>
                      <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                        Active
                      </span>
                    </td>
                    <td className='whitespace-nowrap px-3 py-5 text-sm text-gray-500'>
                      {program.name}
                    </td>
                    <td className='relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
                      <a
                        href={`/saved/edit/${program.product_id}`} // Correctly interpolate the product_id
                        className='text-indigo-600 hover:text-indigo-900'
                      >
                        Edit<span className='sr-only'>, {program.name}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SavedPrograms
