import './App.css';
import Dashboard from './admin/Dashboard/Dashboard';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PartnerPrograms from './features/PartnerProgram/PartnerPrograms';
import RoadMap from './RoadMap';
import ProductProfile from './features/Product/ProductProfile';
import LogInPage from './auth/LogInPage';
import SignUpPage from './auth/SignUpPage';
import LogoutPage from './auth/LogoutPage';
import PrivateRoute from './auth/components/PrivateRoute';
import { AuthProvider } from './auth/components/AuthContext';
import RedirectToDashboardOrLogin from './auth/RedirectToDashboardOrLogin';
import { useUser } from './auth/UserContext';
import { useEffect } from 'react';
import SavedPrograms from './features/SavedPrograms';
import UserProfile from './admin/UserProfile/UserProfile';
import PartnerProgramEdit from './features/PartnerProgram/PartnerProgramEdit';
import Products from './features/Product/Products';

function App() {
  const { setUser } = useUser();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      // Fetch user details from your backend using the stored userId
      const fetchUserDetails = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/details?id=${encodeURIComponent(userId)}`);
          if (!response.ok) throw new Error('Failed to fetch user details');
          const userDetails = await response.json();
          setUser({
            uid: userId,
            email: userDetails.email,
            displayName: userDetails.display_name,
          });
        } catch (error) {
          console.error('Error fetching user details:', error.message);
          // Optionally, clear localStorage if fetching fails
          localStorage.removeItem('userId');
        }
      };

      fetchUserDetails();
    }
  }, [setUser]);
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LogInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/" element={<RedirectToDashboardOrLogin />} />
          
          <Route element={<Dashboard />}>
            {/* Protect the nested routes with PrivateRoute */}
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<div>Dashboard Home</div>} />
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/roadmap" element={<RoadMap />} />
              <Route path="/programs" element={<PartnerPrograms />} />
              <Route path="/products" element={<Products />} />
              <Route path="/product/:productId" element={<ProductProfile />} />
              <Route path="/saved" element={<SavedPrograms />} /> 
              <Route path='/profile' element={<UserProfile />} />
              <Route path="/saved/edit/:programId" element={<PartnerProgramEdit />} />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
