import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition, Menu, Disclosure } from '@headlessui/react'
import {
  EllipsisHorizontalIcon,
  XMarkIcon,
  ChevronDownIcon,
  HeartIcon
} from '@heroicons/react/24/outline'
import { useUser } from '../../auth/UserContext'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom' // Import useNavigate

const filterOptions = [
  {
    category: 'Best for',
    options: ['Individuals', 'Small businesses', 'Enterprises']
  },
  {
    category: 'Alternative to',
    options: ['Slack', 'Trello', 'Asana', 'Jira', 'Zoom', 'Google Meet']
  },
  {
    category: 'Integrations',
    options: ['API', 'Webhooks', 'Zapier', 'Integromat']
  },
  {
    category: 'Operating system',
    options: ['Browser-based', 'Windows', 'macOS', 'Linux', 'iOS', 'Android']
  },
  {
    category: 'Features',
    options: [
      'Task management',
      'Team chat',
      'File sharing',
      'Video conferencing',
      'Calendar',
      'Time tracking'
    ]
  },
  {
    category: 'Compliance',
    options: ['GDPR', 'SOC II', 'ISO', 'HIPPAA']
  }
]

const Products = () => {
  const { user } = useUser()
  const [products, setProducts] = useState([])
  const [open, setOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const firstPageLoaded = useRef(false) // Ref to track if the first page has been loaded
  const [savedProductIds, setSavedProductIds] = useState([])
  const [page, setPage] = useState(1);
  const loader = useRef(null);

  const navigate = useNavigate() // Initialize useNavigate

  const openModal = product => {
    setSelectedProduct(product)
    setOpen(true)
  }

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'your-api-base-url'

// Your imports and other component setup remain the same

const fetchProducts = async (currentPage) => {
  if (!hasMore || isLoading || (currentPage === 1 && firstPageLoaded.current)) return;

  setIsLoading(true);
  try {
    const response = await fetch(`${API_BASE_URL}/api/products?page=${currentPage}`);
    if (!response.ok) throw new Error('Network response was not ok');

    const newData = await response.json();
    setProducts(prev => [...prev, ...newData.products]);
    setHasMore(newData.hasMore);
    if (newData.products.length > 0) {
      setPage(currentPage + 1);
    }
    firstPageLoaded.current = true; // Mark the first page as loaded
  } catch (error) {
    console.error('There was a problem fetching the products:', error);
  } finally {
    setIsLoading(false);
  }
};

useEffect(() => {
  fetchProducts(page); // This ensures the first page is fetched on component mount
}, []);

useEffect(() => {
  const handleScroll = () => {
    const nearBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 500;
    if (nearBottom && hasMore && !isLoading) {
      fetchProducts(page);
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, [page, hasMore, isLoading]);




  useEffect(() => {
    const fetchSavedFavorites = async () => {
      if (user && user.uid) {
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/get_favorites/${user.uid}`
          )
          const data = await response.json()
          // Assuming 'data' is an array of products
          const savedIds = data.map(item => item.product_id)
          setSavedProductIds(savedIds)
        } catch (error) {
          console.error('Failed to fetch saved products:', error)
        }
      }
    }

    fetchSavedFavorites()
  }, [user])

  const saveProduct = async product_id => {
    if (!user || !user.uid) {
      console.error('User not authenticated')
      return
    }

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
    try {
      const response = await fetch(`${API_BASE_URL}/api/add_to_favorites`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firebase_uid: user.uid,
          product_id: product_id // Use product_id from the product
        })
      })

      if (!response.ok) {
        throw new Error('Failed to save the product')
      }

      console.log('Product saved successfully')
      // Optionally, refresh the list of saved products or provide UI feedback here
    } catch (error) {
      console.error('Error saving the product:', error)
      // Provide error feedback to the user here
    }
    // On successful save:
    setSavedProductIds(prevIds => {
      // Add product_id to the list if it's not already there
      if (!prevIds.includes(product_id)) {
        return [...prevIds, product_id]
      }
      return prevIds
    })
  }

  const isProductSaved = productId => {
    return savedProductIds.includes(productId)
  }

  const toggleSaveProduct = async product_id => {
    if (!user || !user.uid) {
      console.error('User not authenticated')
      return
    }

    const isSaved = isProductSaved(product_id)

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/${
          isSaved ? 'remove_from_favorites' : 'add_to_favorites'
        }`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            firebase_uid: user.uid,
            product_id: product_id
          })
        }
      )

      if (!response.ok) {
        throw new Error(`Failed to ${isSaved ? 'unsave' : 'save'} the product`)
      }

      // Update savedProductIds based on whether we're saving or unsaving
      setSavedProductIds(prevIds => {
        return isSaved
          ? prevIds.filter(id => id !== product_id)
          : [...prevIds, product_id]
      })

      console.log(`Product ${isSaved ? 'unsaved' : 'saved'} successfully`)
    } catch (error) {
      console.error(
        `Error ${isSaved ? 'unsaving' : 'saving'} the product:`,
        error
      )
    }
  }

  const unsaveProduct = async product_id => {
    if (!user || !user.uid) {
      console.error('User not authenticated')
      return
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/remove_from_favorites`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            firebase_uid: user.uid,
            product_id: product_id
          })
        }
      )

      if (!response.ok) {
        throw new Error('Failed to unsave the product')
      }

      console.log('Product unsaved successfully')
      // Remove productId from the list
      setSavedProductIds(prevIds => prevIds.filter(id => id !== product_id))
    } catch (error) {
      console.error('Error unsaving the product:', error)
    }
  }

  const toggleFavorite = async productId => {
    if (!user || !user.uid) {
      console.error('User not authenticated')
      return
    }

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
    const endpoint = isProductSaved(productId)
      ? '/api/remove_from_favorites'
      : '/api/add_to_favorites'

    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ firebase_uid: user.uid, product_id: productId })
      })

      if (!response.ok) {
        throw new Error(
          `Failed to ${
            isProductSaved(productId) ? 'unsave' : 'save'
          } the product`
        )
      }

      console.log(
        `Product ${
          isProductSaved(productId) ? 'removed from' : 'added to'
        } favorites successfully`
      )

      // Update savedProductIds state based on action taken
      setSavedProductIds(prevIds => {
        return isProductSaved(productId)
          ? prevIds.filter(id => id !== productId)
          : [...prevIds, productId]
      })
    } catch (error) {
      console.error('Error updating the favorite status:', error)
    }
  }

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='lg:flex-basis-80 lg:flex-grow-0 lg:flex-shrink-0 p-4 border-b lg:border-b-0 lg:border-r border-gray-200'>
        <div className='flex flex-col space-y-2'>
          {/* New menu item for saved products */}
          <div
            className='flex items-center p-2 text-gray-900 hover:bg-gray-50 cursor-pointer'
            onClick={() => navigate('/saved')} // Navigate to /saved on click
          >
            <HeartIcon
              className='h-6 w-6 text-red-500 mr-2'
              aria-hidden='true'
            />
            <span>Saved Products</span>
          </div>
          {filterOptions.map(section => (
            <Disclosure
              key={section.category}
              as='div'
              className='border-t border-gray-200'
            >
              {({ open }) => (
                <>
                  <Disclosure.Button className='flex justify-between w-full px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                    <span>{section.category}</span>
                    <ChevronDownIcon
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-5 h-5 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className='px-4 pt-4 pb-2 text-sm text-gray-500'>
                    {section.options.map((option, index) => (
                      <div key={index} className='py-1'>
                        {option}
                      </div>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
      <div ref={loader} style={{ height: "20px" }}></div>
      <div>
        <ul
          role='list'
          className='grid grid-cols-1 gap-x-6 gap-y-8 xl:gap-x-8 p-4'
        >
          {products.map((product, index) => (
            <li
              key={`${product.id}-${index}`}
              className='overflow-hidden rounded-xl border border-gray-200'
            >
              <div className='flex items-center gap-x-4 border-b border-gray-600/5 bg-gray-50 p-6'>
                <img
                  src={product.logo}
                  alt={product.name}
                  className='h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-600/10'
                />
                <div className='text-sm font-medium leading-6 text-gray-600'>
                  {product.name}
                </div>
                <Menu as='div' className='relative ml-auto'>
                  <Menu.Button className='-m-2.5 block p-2.5 text-gray-400 hover:text-gray-400'>
                    <span className='sr-only'>Open options</span>
                    <EllipsisHorizontalIcon
                      className='h-5 w-5'
                      aria-hidden='true'
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-600/5 focus:outline-none'>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href='#'
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-600'
                            )}
                          >
                            View
                            <span className='sr-only'>, {product.name}</span>
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              if (isProductSaved(product.product_id)) {
                                // Call an unsave function
                                unsaveProduct(product.product_id)
                              } else {
                                // Existing save function
                                saveProduct(product.product_id)
                              }
                            }}
                            className={`${
                              active ? 'bg-gray-50' : ''
                            } block w-full text-left px-3 py-1 text-sm leading-6 text-gray-600`}
                          >
                            {isProductSaved(product.product_id)
                              ? 'Unsave'
                              : 'Save'}
                            <span className='sr-only'>, {product.name}</span>
                          </button>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href='#'
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-600'
                            )}
                          >
                            Report
                            <span className='sr-only'>, {product.name}</span>
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <dl className='grid grid-cols-1 md:grid-cols-3 -my-3 px-6 py-4 text-sm leading-6'>
                {/* Column 1: Commission Details - takes up 2 fractions (66.66%) */}
                <div className='flex flex-col md:col-span-2 border-b md:border-b-0 md:border-r border-gray-200 py-3 md:py-0 md:pr-4'>
                  <div className='flex items-center gap-x-2 py-3'>
                    <dd className='text-gray-400'>
                      {product?.description || 'No description available'}
                    </dd>
                  </div>
                  <div className='flex items-center gap-x-2 py-3'>
                    {/* <dt className="text-gray-400">Offer:</dt> */}
                    <dd className='text-gray-700'>
                      {product?.offer || 'No description available'}
                    </dd>
                  </div>
                  {/* ... more details if needed ... */}
                </div>

                {/* Column 2: Actions - takes up 1 fraction (33.33%) */}
                <div className='flex flex-col md:col-span-1 justify-center gap-y-2 pl-4 py-3'>
                  <button
                    onClick={() => openModal(product)}
                    className='block text-center rounded-md border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none'
                  >
                    View details
                  </button>
                  {/* ... other buttons if needed ... */}
                </div>
              </dl>
            </li>
          ))}
        </ul>

        {/* Modal for Product Details */}
        {selectedProduct && (
          <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-50' onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-400'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-400'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 overflow-hidden'>
                <div className='absolute inset-0 overflow-hidden'>
                  <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
                    <Transition.Child
                      as={Fragment}
                      enter='transform transition ease-in-out duration-400 sm:duration-700'
                      enterFrom='translate-x-full'
                      enterTo='translate-x-0'
                      leave='transform transition ease-in-out duration-400 sm:duration-700'
                      leaveFrom='translate-x-0'
                      leaveTo='translate-x-full'
                    >
                      <Dialog.Panel className='pointer-events-auto relative w-96'>
                        <Transition.Child
                          as={Fragment}
                          enter='ease-in-out duration-400'
                          enterFrom='opacity-0'
                          enterTo='opacity-100'
                          leave='ease-in-out duration-400'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <div className='absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4'>
                            <button
                              type='button'
                              className='relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                              onClick={() => setOpen(false)}
                            >
                              <span className='absolute -inset-2.5' />
                              <span className='sr-only'>Close panel</span>
                              <XMarkIcon
                                className='h-6 w-6'
                                aria-hidden='true'
                              />
                            </button>
                          </div>
                        </Transition.Child>
                        <div className='h-full overflow-y-auto bg-white p-8'>
                          <div className='space-y-6 pb-16'>
                            <div>
                              <div className='flex items-center gap-x-4 border-b border-gray-600/5 pb-6'>
                                <img
                                  src={selectedProduct.logo}
                                  alt={selectedProduct.name}
                                  className='h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-600/10'
                                />
                                <div className='text-sm font-medium leading-6 text-gray-600'>
                                  {selectedProduct.name} Partner Product
                                </div>
                                <button
                                  onClick={() =>
                                    toggleFavorite(selectedProduct.product_id)
                                  }
                                  className={`ml-4 flex h-8 w-8 items-center justify-center rounded-full ${
                                    isProductSaved(selectedProduct.product_id)
                                      ? 'bg-red-500'
                                      : 'bg-white'
                                  } text-gray-400 hover:bg-gray-100 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400`}
                                >
                                  <HeartIcon
                                    className='h-6 w-6'
                                    aria-hidden='true'
                                  />
                                  <span className='sr-only'>Favorite</span>
                                </button>
                              </div>
                            </div>
                            <div>
                              <h3 className='font-medium text-gray-600'>
                                Commission Information
                              </h3>
                              <dl className='mt-2 divide-y divide-gray-200 border-b border-t border-gray-200'>
                                <div className='flex justify-between py-3 text-sm font-medium'>
                                  <dt className='text-gray-400'>
                                    Commission rate
                                  </dt>
                                  <dd className='text-gray-600'>
                                    {selectedProduct.commission_rate ||
                                      'No description available'}
                                  </dd>
                                </div>
                                <div className='flex justify-between py-3 text-sm font-medium'>
                                  <dt className='text-gray-400'>
                                    Commission frequency
                                  </dt>
                                  <dd className='text-gray-600'>
                                    {selectedProduct.commission_frequency ||
                                      'No description available'}
                                  </dd>
                                </div>
                                <div className='flex justify-between py-3 text-sm font-medium'>
                                  <dt className='text-gray-400'>
                                    Commission type
                                  </dt>
                                  <dd className='text-gray-600'>
                                    {selectedProduct.PartnerProduct
                                      ?.commission_type ||
                                      'No description available'}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                            <div>
                              <h3 className='font-medium text-gray-600'>
                                Product Information
                              </h3>
                              <dl className='mt-2 divide-y divide-gray-200 border-b border-t border-gray-200'>
                                <div className='flex justify-between py-3 text-sm font-medium'>
                                  <dt className='text-gray-400'>
                                    Product type
                                  </dt>
                                  <dd className='text-gray-600'>
                                    {selectedProduct.PartnerProduct
                                      ?.product_type ||
                                      'No description available'}
                                  </dd>
                                </div>
                                <div className='flex justify-between py-3 text-sm font-medium'>
                                  <dt className='text-gray-400'>
                                    Affiliate network
                                  </dt>
                                  <dd className='text-gray-600'>
                                    {selectedProduct.affiliate_network_name ||
                                      'No description available'}
                                  </dd>
                                </div>
                                <div className='flex justify-between py-3 text-sm font-medium'>
                                  <dt className='text-gray-400'>Countries</dt>
                                  <dd className='text-gray-600'>
                                    {selectedProduct.PartnerProduct
                                      ?.countries || 'No description available'}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                            <div>
                              <h3 className='font-medium text-gray-600'>
                                Description
                              </h3>
                              <div className='mt-2 flex items-center justify-between'>
                                <p className='text-sm text-gray-400'>
                                  {selectedProduct.description ||
                                    'No description available'}
                                </p>
                              </div>
                            </div>
                            <div className='flex'>
                              <button
                                type='button'
                                className='flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                onClick={() => {
                                  if (
                                    !selectedProduct ||
                                    !selectedProduct.domain
                                  ) {
                                    console.error(
                                      'Domain URL is undefined or selectedProduct is not loaded'
                                    )
                                    return
                                  }
                                  window.open(selectedProduct.domain, '_blank')
                                }}
                              >
                                Visit product page
                              </button>
                              <button
                                type='button'
                                className='ml-3 flex-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                              >
                                Registration page
                              </button>
                            </div>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )}
      </div>
    </div>
  )
}

export default Products
