import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './components/AuthContext'; // Adjust path as needed

const RedirectToDashboardOrLogin = () => {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/dashboard" /> : <Navigate to="/login" />;
};

export default RedirectToDashboardOrLogin;
