import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase'; // Ensure this path correctly points to your Firebase config

class AuthService {
    // Sign up with email and password
    async signup(email, password) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        // Return user details including uid and email
        return {
            uid: user.uid,
            email: user.email,
        };
    }

    // Login with email and password and get ID token
    async login(email, password) {
        const result = await signInWithEmailAndPassword(auth, email, password);
        const idToken = await result.user.getIdToken();
        // Return both user and token
        return { idToken, ...result.user };
    }

    // Sign out
    logout() {
        return signOut(auth);
    }

    // Get the ID token of the current user
    getCurrentUserToken() {
        return auth.currentUser ? auth.currentUser.getIdToken(true) : Promise.resolve(null);
    }
}

export default new AuthService();
