import React from 'react';

const RoadMap = () => {
  return (
    <div className="embedded-container" style={{ width: '100%', height: '100%', position: 'relative' }}>
      <iframe
        src="https://coda.io/embed/FY9s3P3sYK/_suLsq?viewMode=embedplay&hideSections=true"
        width="1200"
        height="1200"
        style={{ maxWidth: '100%', border: 'none' }}
        allow="fullscreen"
        title="Embedded Content"
      ></iframe>
    </div>
  );
};

export default RoadMap;
