import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function PartnerProgramEdit() {
  const { programId } = useParams(); // Extract the program_id from the URL
  const [programDetails, setProgramDetails] = useState(null);

  useEffect(() => {
    const fetchProgramDetails = async () => {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      try {
        const response = await fetch(`${API_BASE_URL}/api/programs/${programId}`);
        if (response.ok) {
          const data = await response.json();
          setProgramDetails(data); // Save the fetched data to state
        } else {
          throw new Error('Failed to fetch program details');
        }
      } catch (error) {
        console.error('Fetching program details error:', error);
      }
    };

    if (programId) { // Ensure programId is not undefined or null
      fetchProgramDetails();
    }
  }, [programId]); // Dependency array to re-fetch if programId changes

  // Check if programDetails is null and return loading state or error message as needed
  if (!programDetails) {
    return <div>Loading...</div>;
  }

  return (
    <form>
      {/* Form content */}
      {/* Example of populating a field with fetched data */}
      <div className="sm:col-span-4">
        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
        {programDetails.name}
        </label>
        <div className="mt-2">
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
            <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">affiliate.xaassearch.com/</span>
            <input
              type="text"
              name="username"
              id="username"
              autoComplete="username"
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="janesmith"
              value={programDetails.username || ''} // Use the fetched program details to populate the field
              // onChange handler to update state as needed
            />
          </div>
        </div>
      </div>
      {/* Other fields */}
    </form>
  );
}
