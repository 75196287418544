import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './components/firebase';
import { signOut } from 'firebase/auth';

export default function LogoutPage() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out the user
      localStorage.removeItem('userId'); // Clear stored user identifier
      setUser(null); // Reset user context
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Logout error:', error.message);
      // Optionally, handle errors (e.g., show an error message)
    }
  };
  

  return (
    <div className="flex min-h-full flex-1 items-center justify-center">
      <div className="text-center">
        <h2 className="text-2xl font-semibold">Are you sure you want to logout?</h2>
        <div className="mt-6">
          <button
            onClick={handleLogout}
            className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
