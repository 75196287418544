import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from './firebase'; // Make sure this path is correct
import AuthService from './AuthService';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [idToken, setIdToken] = useState('');

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            if (user) {
                AuthService.getCurrentUserToken().then(token => {
                    setIdToken(token);
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        idToken
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
